.date-picker-global{
    border: 1px solid rgba(146, 146, 146, 1);
    font-size: 1rem;
    line-height: 1.5;
    height: 3rem;
}
.react-datepicker-wrapper{
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    border: 1px solid rgba(146, 146, 146, 1);
    font-size: 1rem;
    line-height: 1.5;
    height: 3rem;
    border-radius: 4px;
    padding:8px;
    background-color: rgba(255, 255, 255, 1);
}