.toast-upload [class^="btn__label"] {
    color: rgba(72, 156, 227, 1);
    &:hover {
        text-decoration: underline;
    }
  }
  .inline-message {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; 
    max-width: 300px; 
  }