.pending-container {
  .btn--small {
    display: flex;
    justify-content: flex-end !important;
    position: relative;
    width: auto;
    z-index: 3;
  }

  >.btn--small {
    float: right;
    top: 50px;
  }
}

.parent-tab {
  padding-left: 20px;
  padding-right: 20px;
}

.pendingApprovalPanel {
  padding: 0;
}

.UploadSubmission {
  padding-right: 20px;
}
// .tabs__panel {
//   padding: 0px;
// }