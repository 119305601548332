.commodity-tabs {
    display: flex; /* Use Flexbox for layout */
    gap: 10px;
    flex-direction: column;
}

.commodity .accordion {
    background-color: rgb(235, 235, 241);
}
.producer-accordion .accordion {
    background-color: rgb(183, 183, 190);
    width: 90%;
    margin: 0 auto
}
.producer-accordion .accordion {
    background-color: rgb(255, 255, 255); /* Adjust the color as needed */
    max-width: 100%; /* Ensure the container doesn't exceed the viewport width */
    padding: 10px; /* Add some padding for better spacing */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    display: flex;
    flex-wrap: wrap; /* Allow the content to wrap within the container */
}

// .producer-accordion .accordion-item {
//     flex: 1 1 auto; /* Allow the item to grow and shrink as needed */
//     min-width: 0; /* Prevent overflow */
// }

.producer-accordion .input-field,
.producer-accordion .select {
    flex: 1 1 auto; /* Allow the field to grow and shrink as needed */
    min-width: 0; /* Prevent overflow */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    word-wrap: break-word; /* Break long words to fit within the container */
}
.production-Place .accordion{
    background-color: rgb(235, 235, 241);
    width: 1300px;
    margin-right: 10px;
    margin-left: 30px;
}
.quantity-tabs {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  
  .quantity-tabs > * {
    margin-right: 10px; /* Adjust spacing between elements as needed */
  }

.hs-heading {
    display: flex; /* Use Flexbox for layout */
    gap: 10px;
    flex-direction: row;
}
.description{
    width: 100%;
}
.production-place{
    display: flex; /* Use Flexbox for layout */
    gap: 10px;
    margin: 5px;
}
.production-place-accordion {
    width: auto !important;
}